<template>
  <div>
    <!-- 중앙모달 -->
    <div :class="{ modal: true, center: true, joinCode: true, on: true }">
      <section class="inner">
        <header>
          <h2>인증코드입력</h2>
        </header>
        <form @submit.prevent="submit">
          <fieldset>
            <legend>인증코드 6자리를 입력하세요</legend>
            <div class="codeNum">
              <input
                ref="input"
                type="text"
                v-model="authCode[0]"
                v-on:keyup="handleKey"
                maxlength="1"
              />
              <input
                type="text"
                v-model="authCode[1]"
                v-on:keyup="handleKey"
                maxlength="1"
              />
              <input
                type="text"
                v-model="authCode[2]"
                v-on:keyup="handleKey"
                maxlength="1"
              />
              <input
                type="text"
                v-model="authCode[3]"
                v-on:keyup="handleKey"
                maxlength="1"
              />
              <input
                type="text"
                v-model="authCode[4]"
                v-on:keyup="handleKey"
                maxlength="1"
              />
              <input
                type="text"
                v-model="authCode[5]"
                v-on:keyup="handleKey"
                v-on:keyup.enter="submit"
                maxlength="1"
              />
            </div>
            <em class="timer">
              <b>유효시간</b>
              <time>{{ minutes }}:{{ seconds }}</time>
            </em>

            <article>
              인증코드가 포함된 문자메세지가 고객님의 기기로 전송되었습니다.
              <br />받은 인증코드 6자리를 5분안에 입력해주세요. 인증코드를
              발송받지 못하셨나요?
              <a href="#" class="link" @click="requestAuthNo"
                >재요청 하시려면 여기를 클릭하세요</a
              >
            </article>
            <div class="btns">
              <button type="submit" class="on">확인</button>
            </div>
          </fieldset>
        </form>
      </section>
    </div>
  </div>
</template>
<script>
// import _ from "lodash";
import { createConfirmAuth } from "@/api/member";
import { createLogin } from "@/api/member";
import { mapState } from "vuex";
import { timer } from "@/mixins/timer";
// import { eventBus } from "@/main";
export default {
  mixins: [timer],
  data() {
    return {
      visible: false,
      message: "정상적으로 처리 되었습니다.",
      authCode: [],
      authCodeSendIdx: null,
      showSnackbar: false,
      resetAlert: false,
      count: null,
    };
  },
  created() {
    this.resetAuthNo();
  },
  mounted() {
    this.$refs.input.focus();
    this.timer();
  },
  computed: {
    ...mapState(["authData", "userInfo"]),
  },
  methods: {
    resetAuthNo() {
      setTimeout(() => {
        this.resetAlert = true;
      }, 300000);
    },
    handleKey(event) {
      let code = event.keyCode;
      if (code > 47 && code < 58) {
        event.target.nextElementSibling.focus();
        return;
      } else if (code == 37) {
        event.target.previousElementSibling.focus();
        return;
      } else if (code == 39) {
        event.target.nextElementSibling.focus();
        return;
      } else if (code == 8) {
        event.target.previousElementSibling.focus();
        return;
      }
      event.preventDefault();
    },
    submit() {
      let formdata = new FormData();
      formdata.append("authCodeSendIdx", this.authData.authCodeSendIdx);
      formdata.append("password", this.authData.password);
      formdata.append("mobileNo", this.authData.mobileNo);
      formdata.append("authCode", this.authCode.join(""));
      formdata.append("userIdx", this.authData.userIdx);
      formdata.append("gubun", this.authData.gubun); //gubun은 state에서관리하고 있어요. 별도 파일에서.. 여기는 지ㅐㅜ

      createConfirmAuth(formdata).then((res) => {
        if (res.data.status == 201) {
          // this.showSnackbar = true;
          // this.message = "인증이 완료 되었습니다.";
          let data = {
            mobileNo: this.authData.mobileNo,
            accessToken: res.data.data.accessToken,
          };
          this.endTimer();
          if (this.authData.type == "join") {
            localStorage.setItem("accessToken", res.data.data.accessToken);
            this.$router.push({ name: "joinDone" });
          } else {
            setTimeout(() => {
              this.$store.dispatch("LOGIN", { data });
            }, 1000);
          }
        } else if (res.data.status == 401) {
          let message = "인증번호를 다시 입력해 주세요.";
          alert(message);
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    requestAuthNo() {
      let formdata = new FormData();
      formdata.append("mobileNo", this.authData.mobileNo);
      formdata.append("password", this.authData.password);
      this.endTimer();

      this.authCode = [];
      createLogin(formdata).then((res) => {
        if (res.data.status === 201) {
          this.timer();
          let data = {
            authCodeSendIdx: res.data.data.authCodeSendIdx,
            userIdx: res.data.data.userIdx,
            gubun: "wlogin",
          };
          this.showSnackbar = true;
          this.message = "인증코드가 재요청 되었습니다.";
          this.$store.dispatch("SET_AUTH_DATA", { data });
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
