<template>
  <div>
    <div class="Login base-height">
      <div class="login_left"></div>
      <div class="mainBox">
        <h1>
          <router-link to="/">
            <img src="@/assets/images/reviewmind/logo2.png" alt="로고" />
          </router-link>
        </h1>

        <h2>{{ $t("login_title") }}</h2>

        <div class="form">
          <form>
            <fieldset>
              <legend>로그인</legend>
              <div class="cover bg">
                <input v-model="email" type="email" :placeholder="$t('inputEmail')" class="error" />
                <em v-show="errMsg" class="errMsg">{{ $t("loginErrMsg") }}</em>
              </div>

              <div class="cover bg">
                <input v-model="password" type="password" :placeholder="$t('inputPwd')" />
                <em v-show="errMsg" class="errMsg">{{ $t("loginErrMsg") }}</em>
              </div>
              <!-- 
              <p>
                <input v-model="autoLogin" type="checkbox" id="autoLogin" />
                <label for="autoLogin">로그인 상태 유지</label>
              </p>-->
              <button type="button" class="btn full primary" @click="submit">
                {{ $t("emailLogin") }}
              </button>
              <div class="or">
                <span></span>
                <p>OR</p>
                <span></span>
              </div>

              <button type="button" class="btn full facebookColor" @click="logInWithFacebook()">
                <img src="@/assets/images/logo_facebook.png" />
                {{ $t("facebookLogin") }}
              </button>
              <button type="button" class="btn full googleColor" @click="googleLogin()">
                <img src="@/assets/images/logo_google.png" />
                {{ $t("googleLogin") }}
              </button>
            </fieldset>
          </form>
        </div>

        <!-- <div class="grid c2">
          <router-link to="/resetPassword">비밀번호 재설정</router-link>
        </div>-->
        <aside class="flexL">
          <router-link to="/join" class="btn">{{ $t("join") }}</router-link>
          <router-link to="/findEmail" class="btn">{{ $t("findId") }}</router-link>
          <router-link to="/findPassword" class="btn">{{ $t("findPwd") }}</router-link>
        </aside>
      </div>
    </div>
    <transition name="fade">
      <div v-if="visible" class="dim on">
        <AuthCode />
      </div>
    </transition>
  </div>
</template>
<script>
import axios from "axios";
import sha256 from "js-sha256";
import { createLogin } from "@/api/member";
import { mapState } from "vuex";
import { format } from "@/mixins/format";
import AuthCode from "@/components/auth/Authcode";
import { createFacebookLogin } from "@/api/index";
import { baseUrl } from "@/config/env";
import { GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from "@/config/key";
import { setNavbar } from "@/mixins/setNavbar";
import { setFooter } from "@/mixins/setFooter";
export default {
  mixins: [format, setNavbar, setFooter],
  components: { AuthCode },
  data() {
    return {
      baseUrl: baseUrl,
      showSnackbar: false,
      client_id: GOOGLE_CLIENT_ID,
      app_id: FACEBOOK_APP_ID,
      visible: false,
      email: "",
      password: "",
      show: false,
      errMsg: false,
      snsLogin: true,
    };
  },
  mounted() {
    this.initGoogle();
    this.initFacebook();
    this.setNavbar(false);
    this.setFooter(false);
  },
  computed: {
    ...mapState(["isLogin", "isLoginError"]),
  },
  methods: {
    initGoogle() {
      window.gapi.load("auth2", () => {
        window.gapi.auth2.init({
          client_id: this.client_id,
          scope: "profile email",
        });
      });
    },
    googleLogin() {
      const gauth = window.gapi.auth2.getAuthInstance();

      gauth.signIn().then((googleUser) => {
        let id_token = googleUser.getAuthResponse().id_token;
        axios({
          method: "POST",
          url: baseUrl + "/auth/google",
          data: {
            tokenId: id_token,
          },
        }).then((res) => {
          if (res.data.status === 200) {
            let data = {
              accesstoken: res.data.accesstoken,
              email: res.data.userInfo.email,
              username: res.data.userInfo.username,
            };
            this.$store.dispatch("LOGIN", data);
          } else {
            alert(this.$t("loginFail"));
          }
        });
      });
    },
    async logInWithFacebook() {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
      window.FB.login(
        (response) => {
          if (response.status === "connected") {
            let data = {
              userId: response.authResponse.userID,
              accessToken: response.authResponse.accessToken,
            };
            createFacebookLogin(data).then((res) => {
              if (res.data.status === 200) {
                let data = {
                  accesstoken: res.data.accesstoken,
                  username: res.data.userInfo.username,
                };
                this.$store.dispatch("LOGIN", data);
              } else {
                alert(this.$t("loginFail"));
              }
            });
          } else {
            alert("User cancelled login or did not fully authorize.");
          }
        },
        { scope: "public_profile,email" }
      );
      return false;
    },
    async initFacebook() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: this.app_id, //You will need to change this
          cookie: false, // This is important, it's not enabled by default
          version: "v10.0",
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/ko_KR/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    Login(authObj) {
      axios
        .post(this.baseUrl + `/auth/kakao/callback`, {
          accesstoken: authObj.access_token,
        })
        .then((res) => {
          let data = {
            accesstoken: res.data.accesstoken,
            email: res.data.email,
          };
          this.$store.dispatch("LOGIN", data);
        })
        .catch((err) => {
          window.console.log(err);
        });
    },
    getPhoneMask(val) {
      let res = this.getMask(val);
      this.mobileNo = res;
      //서버 전송 값에는 '-' 를 제외하고 숫자만 저장
      // this.model.mobileNo = this.mobileNo.replace(/[^0-9]/g, "");
    },
    inNumber(event) {
      if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 46) {
        return;
      } else {
        return false;
      }
    },
    removeChar(event) {
      if (event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 37 || event.keyCode == 39) return;
      else event.target.value = event.target.value.replace(/[^0-9]/g, "");
    },
    handleLogin(type) {
      if (type == "EMAIL") {
        this.snsLogin = false;
      }
    },
    submit() {
      if (this.email == "") {
        // this.errMsg = true;
        return alert("이메일을 입력해 주세요.");
      } else if (this.emailValidate(this.email) == false) {
        return alert("이메일 형식이 맞지 않습니다.");
      } else if (this.password == "") {
        return alert(this.$t("inputPassword"));
      }

      let data = {
        email: this.email,
        password: sha256(this.password),
      };
      createLogin(data).then((res) => {
        if (res.data.status === 200) {
          let data = {
            accesstoken: res.data.accesstoken,
            email: this.email,
          };
          setTimeout(() => {
            this.$store.dispatch("LOGIN", data);
          }, 500);
          // this.$router.push({ name: "home" });
        } else if (res.data.status == 401) {
          alert(this.$t("loginFail"));
        } else if (res.data.status == 409) {
          alert(this.$t("loginErrMsg"));
        }
      });
    },
  },
  beforeDestroy() {
    this.setNavbar(true);
    this.setFooter(true);
  },
};
</script>
