"use strict";

export const timer = {
  data: function () {
    return {
      minutes: "",
      seconds: "",
      timerInstance: null
    };
  },
  methods: {
    timer(durationMinute = 5) {
      let duration = 60 * durationMinute;
      let timer = duration,
        minutes,
        seconds;
      this.timerInstance = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        this.minutes = minutes < 10 ? "0" + minutes : minutes;
        this.seconds = seconds < 10 ? "0" + seconds : seconds;
        if (--timer < 0) {
          timer = 0;
          this.endTimer();
        }
      }, 1000);
    },
    endTimer() {
      clearInterval(this.timerInstance);
    }
  }
};
